
import { lazy, Suspense } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import NotFound from "./pages/notFound"
import "bootstrap/dist/css/bootstrap.min.css"
import "./asst/fonts/visby-cf/style.css"
const Reviews = lazy(() => import("./pages/reviews"))
const OnSiteReferral = lazy(() => import("./pages/OnSiteReferral"))
const GetReward = lazy(() => import("./pages/GetReward"))
const PostPurchaseReferral = lazy(() => import("./pages/PostPurchaseReferral"))
const Sliders = lazy(() => import("./components/Sliders/Sliders"))
const Popup = lazy(() => import("./components/Popup/Popup"))
const PDPWidget = lazy(() => import("./components/PDPWidget/PDPWidget"))
const Rcf = lazy(() => import("./pages/Rcf"))
const NuggetsSlider = lazy(() => import("./components/NuggetsSlider/NuggetsSlider"))
const Attributes = lazy(() => import("./components/Attributes/Attributes"))
const PopupCard = lazy(() => import("./components/PopupCard/PopupCard"))
const SidebarWidget = lazy(() => import("./components/PDPWidget/SiderbarWidget"))
const Ugc = lazy(() => import("./components/Sliders/Ugc"))
const NewRcf = lazy(() => import("./components/v2/rcf/NewRcf"))
const ImportPopUp = lazy(() => import("./components/v2/aliExpress/ImportPopUp"))
const StoreReview = lazy(() => import("./components/v2/storeReview/StoreReview"))
const RcfConfirmation = lazy(() => import ("./components/v2/rcf/RcfConfirmation"))


function App() {
  return (
    <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/review" element={<Reviews />} />
        <Route path="/onsite-referral" element={<OnSiteReferral />} />
        <Route path="/attributes" element={<Attributes />} />
        <Route
          path="/post-checkout-referral"
          element={<PostPurchaseReferral />}
        />
        <Route path="/d/:id" element={<GetReward />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/sliders" element={<Sliders />} />
        <Route path="/popup" element={<Popup />} />
        <Route path="/popup-card" element={<PopupCard />} />
        <Route path="/pdp-widget" element={<PDPWidget />} />
        <Route path="/old-rcf" element={<Rcf />} />
        <Route path="/nuggets-slider" element={<NuggetsSlider />} />
        <Route path="/sidebar" element={<SidebarWidget />} />
        <Route path="/ugc" element={<Ugc />} />
        <Route path="/rcf" element={<NewRcf />} />
        <Route path="/store-review" element={<StoreReview />} />
        <Route path="/import-popup" element={<ImportPopUp />} />
        <Route path="/RcfConfirmation" element={<RcfConfirmation />} />
      </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
